const state = () => ({
  inStockOrderStatusMap: {
    LAUNCH: '创建中',
    REFUSE: '已拒绝',
    COMMIT: '已提交',
    AUDIT: '入库完成',
    // CONFIRMWARE: '已确认入库',
    CANCEL: '已撤销',
  },
  transferStockOrderStatusMap: {
    LAUNCH: '创建中',
    COMMIT: '已调出，待调入',
    CONFIRMWARE: '调拨完成',
  },
  salesOrderStatusMap: {
    LAUNCH: '创建中',
    COMMIT: '已提交',
    WAIT: '待发货',
    SEND: '已发货',
    CANCEL: '已取消',
    REFUSE: '审核拒绝',
    PartRefund: '部分退款',
    AllRefund: '全退款',
  },
  salesOrderReturnStatusMap: {
    NoRefund: '未退款',
    PartRefund: '部分退款',
    AllRefund: '全退款',
  },
  outStockOrderStatusMap: {
    STOCKUP: '备货中',
    COMPLETE: '已出库',
    CANCEL: '已撤销',
  },
  lossStockOrderStatusMap: {
    LAUNCH: '创建中',
    COMMIT: '已提交',
    AUDIT: '损耗完成',
    CANCEL: '已撤销',
    REFUSE: '审核拒绝',
  },
  checkStockOrderStatusMap: {
    WAIT: '待盘点',
    RUNNING: '盘点中',
    FINISH: '盘点完成',
  },
  checkStockOrderTypeMap: {
    FULL: '整仓盘点',
    APPOINT: '指定SKU盘点',
  },
  checkStockResultMap: {
    LOSS: '盘亏',
    EQUAL: '盘平',
    WIN: '盘盈',
  },
  receiptOrderStatusMap: {
    OWE: '欠款',
    SETTLE: '结清',
  },
  payTypeMap: {
    WECHAT: '微信支付',
    ALIPAY: '支付宝支付',
    BANK: '银行卡支付',
    REFUND: '退货',
  },
  returnOrderStatusMap: {
    Launch: '已发起',
    Submit: '已入库',
  },
  saleRecordTypeMap: {
    Sale: '销售出库',
    InStock: '入库',
    SaleRefund: '销售订单退货',
    InStockCancel: '入库撤销',
    outStockCancel: '出库撤销',
  },
})
const getters = {
  inStockOrderStatusMap: (state) => state.inStockOrderStatusMap,
  outStockOrderStatusMap: (state) => state.outStockOrderStatusMap,
  transferStockOrderStatusMap: (state) => state.transferStockOrderStatusMap,
  salesOrderStatusMap: (state) => state.salesOrderStatusMap,
  lossStockOrderStatusMap: (state) => state.lossStockOrderStatusMap,
  checkStockOrderStatusMap: (state) => state.checkStockOrderStatusMap,
  checkStockOrderTypeMap: (state) => state.checkStockOrderTypeMap,
  checkStockResultMap: (state) => state.checkStockResultMap,
  receiptOrderStatusMap: (state) => state.receiptOrderStatusMap,
  payTypeMap: (state) => state.payTypeMap,
  returnOrderStatusMap: (state) => state.returnOrderStatusMap,
  salesOrderReturnStatusMap: (state) => state.salesOrderReturnStatusMap,
  saleRecordTypeMap: (state) => state.saleRecordTypeMap,
}
const mutations = {}
const actions = {}
export default { state, getters, mutations, actions }
