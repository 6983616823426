import request from '@/utils/request'

/**
 *
 * ---------------------------基本数据--------------------------
 */

// 查询省市区
export function fatchRegionTree(data) {
  return request({
    url: '/admin/address/province/wxArea',
    method: 'get',
    data,
  })
}

// 所有分类-all
export function fatchQueryCategoryAll(data) {
  return request({
    url: '/admin/basics/erpCategory/all',
    method: 'get',
    data,
  })
}

/**
 * ---------------规格组规格值部分---------------------------------------
 */
// 查询规格组列表-分页
export function fatchSpecGroupList(data) {
  return request({
    url: '/admin/basics/erpSpecKey',
    method: 'get',
    data,
  })
}

// 新增规格组
export function fatchAddSpecGroupOne(data) {
  return request({
    url: '/admin/basics/erpSpecKey',
    method: 'post',
    data,
  })
}

// 修改规格组
export function fatchUpdateSpecGroupOne(data) {
  return request({
    url: '/admin/basics/erpSpecKey',
    method: 'put',
    data,
  })
}

// 删除规格组
export function fatchDeleteSpecGroupOne(data) {
  return request({
    url: '/admin/basics/erpSpecKey',
    method: 'delete',
    data,
  })
}

// 查询规格值列表-分页
export function querySpecValueList(data) {
  return request({
    url: '/admin/basics/erpSpecValue',
    method: 'get',
    data,
  })
}

// 新增规格值
export function fatchAddSpecValue(data) {
  return request({
    url: '/admin/basics/erpSpecValue',
    method: 'post',
    data,
  })
}

// 修改规格值
export function fatchUpdateSpecValue(data) {
  return request({
    url: '/admin/basics/erpSpecValue',
    method: 'put',
    data,
  })
}

// 删除规格值
export function fatchDeleteSpecValue(data) {
  return request({
    url: '/admin/basics/erpSpecValue',
    method: 'delete',
    data,
  })
}

// 查询规格组及规格值-所有-供选择使用
export function querySpecValueAll(data) {
  return request({
    url: '/admin/basics/erpSpecKey/allSkuKey',
    method: 'get',
    data,
  })
}

// 查询所有规格值-所有
export function querySpecAll(data) {
  return request({
    url: '/admin/basics/erpSpecValue/all',
    method: 'get',
    data,
  })
}

/**
 * ---------------商品分类部分---------------------------------------
 */
// 查询商品分类列表-分页
export function queryCategoryList(data) {
  return request({
    url: '/admin/basics/erpCategory',
    method: 'get',
    data,
  })
}

// 新增商品分类
export function fatchAddCategoryOne(data) {
  return request({
    url: '/admin/basics/erpCategory',
    method: 'post',
    data,
  })
}

// 修改商品分类
export function fatchUpdateCategoryOne(data) {
  return request({
    url: '/admin/basics/erpCategory',
    method: 'put',
    data,
  })
}
// 删除商品分类
export function fatchDeleteCategoryOne(data) {
  return request({
    url: '/admin/basics/erpCategory',
    method: 'delete',
    data,
  })
}

/**
 * ---------------仓库部分---------------------------------------
 */
// 查询仓库列表-分页
export function queryWarehouseList(data) {
  return request({
    url: '/admin/basics/stock',
    method: 'get',
    data,
  })
}

// 查询仓库列表-全部
export function queryWarehouseListAll(data) {
  return request({
    url: '/admin/basics/stock/all',
    method: 'get',
    data,
  })
}

// 新增仓库
export function fatchAddWarehouseOne(data) {
  return request({
    url: '/admin/basics/stock',
    method: 'post',
    data,
  })
}

// 修改仓库
export function fatchUpdateWarehouseOne(data) {
  return request({
    url: '/admin/basics/stock',
    method: 'put',
    data,
  })
}
// 删除仓库
export function fatchDeleteWarehouseOne(data) {
  return request({
    url: '/admin/basics/stock',
    method: 'delete',
    data,
  })
}

/**
 * ---------------供应商部分---------------------------------------
 */
// 查询供应商列表-分页
export function querySupplierList(data) {
  return request({
    url: '/admin/basics/erpSupplier',
    method: 'get',
    data,
  })
}

// 查询供应商列表-不分页
export function querySupplierListAll(data) {
  return request({
    url: '/admin/basics/erpSupplier/all',
    method: 'get',
    data,
  })
}

// 新增供应商
export function fatchAddSupplierOne(data) {
  return request({
    url: '/admin/basics/erpSupplier',
    method: 'post',
    data,
  })
}

// 修改供应商
export function fatchUpdateSupplierOne(data) {
  return request({
    url: '/admin/basics/erpSupplier',
    method: 'put',
    data,
  })
}
// 删除供应商
export function fatchDeleteSupplierOne(data) {
  return request({
    url: '/admin/basics/erpSupplier',
    method: 'delete',
    data,
  })
}

/**
 * ---------------客户部分---------------------------------------
 */
// 查询客户列表-分页
export function queryCustomerList(data) {
  return request({
    url: '/admin/basics/erpClient',
    method: 'get',
    data,
  })
}

// 新增客户
export function fatchAddCustomerOne(data) {
  return request({
    url: '/admin/basics/erpClient',
    method: 'post',
    data,
  })
}

// 修改客户
export function fatchUpdateCustomerOne(data) {
  return request({
    url: '/admin/basics/erpClient',
    method: 'put',
    data,
  })
}
// 删除客户
export function fatchDeleteCustomerOne(data) {
  return request({
    url: '/admin/basics/erpClient',
    method: 'delete',
    data,
  })
}

// 查询客户-全部
export function queryCustAll(data) {
  return request({
    url: '/admin/basics/erpClient/all',
    method: 'get',
    data,
  })
}

/**
 * ---------------货架部分---------------------------------------
 */
// 根据仓库-查询货架列表-分页
export function queryShelvesList(data) {
  return request({
    url: '/admin/basics/stockShelves',
    method: 'get',
    data,
  })
}

// 新增货架
export function fatchAddShelvesOne(data) {
  return request({
    url: '/admin/basics/stockShelves',
    method: 'post',
    data,
  })
}

// 修改货架
export function fatchUpdateShelvesOne(data) {
  return request({
    url: '/admin/basics/stockShelves',
    method: 'put',
    data,
  })
}
// 删除货架
export function fatchDeleteShelvesOne(data) {
  return request({
    url: '/admin/basics/stockShelves',
    method: 'delete',
    data,
  })
}

// 根据仓库id查询所有货架列表
export function fatchShelvesListByStock(data) {
  return request({
    url: '/admin/basics/stockShelves/list',
    method: 'get',
    data,
  })
}

/**
 * ---------------库位部分---------------------------------------
 */
// 根据货架-查询库位列表-分页
export function queryShelvesLocationList(data) {
  return request({
    url: '/admin/basics/stockShelvesLocation',
    method: 'get',
    data,
  })
}

// 根据货架id查询所有库位列表
export function fatchShelvesLocationListByShelves(data) {
  return request({
    url: '/admin/basics/stockShelvesLocation/list',
    method: 'get',
    data,
  })
}

// 新增库位
export function fatchAddShelvesLocationOne(data) {
  return request({
    url: '/admin/basics/stockShelvesLocation',
    method: 'post',
    data,
  })
}

// 修改库位
export function fatchUpdateShelvesLocationOne(data) {
  return request({
    url: '/admin/basics/stockShelvesLocation',
    method: 'put',
    data,
  })
}
// 删除库位
export function fatchDeleteShelvesLocationOne(data) {
  return request({
    url: '/admin/basics/stockShelvesLocation',
    method: 'delete',
    data,
  })
}

/**
 * ---------------价格规则---------------------------------------
 */
// 查询价格规则分页
export function queryDiscountList(data) {
  return request({
    url: '/admin/basics/erpDiscount',
    method: 'get',
    data,
  })
}

// 新增价格规则
export function addDiscount(data) {
  return request({
    url: '/admin/basics/erpDiscount',
    method: 'post',
    data,
  })
}

// 更新价格规则
export function updateDiscount(data) {
  return request({
    url: '/admin/basics/erpDiscount',
    method: 'put',
    data,
  })
}

// 删除价格规则
export function deleteDiscount(data) {
  return request({
    url: '/admin/basics/erpDiscount',
    method: 'delete',
    data,
  })
}

// 查询价格规则所有
export function queryDiscountAll(data) {
  return request({
    url: '/admin/basics/erpDiscount/all',
    method: 'get',
    data,
  })
}
