<template>
  <div>
    <div class="add_line between">
      <div>
        <span>{{ props.title || '' }}</span>
      </div>
      <a-space>
        <a-button @click="backClick" v-if="props.hasBack"> 返回 </a-button>
        <a-button type="primary" v-if="props.hasAdd" @click="addClick">
          <template #icon><plus-outlined /></template>
          新增
        </a-button>
      </a-space>
    </div>
    <a-table
      :dataSource="dataSource"
      :columns="props.columns"
      :loading="tableLoading"
      :pagination="false"
      v-bind="$attrs"
    >
      <template v-slot:[item]="scope" v-for="item in renderArr">
        <slot :name="item" :scope="scope"></slot>
      </template>
    </a-table>
    <div class="pager_wrap" v-if="pagination">
      <span v-if="summary" class="summary">合计：{{ summaryTxt }}</span>
      <a-pagination
        v-model:current="pager.data.current"
        v-model:pageSize="pager.data.pageSize"
        :total="pager.data.total"
        :pageSizeOptions="['10', '20', '50', '100', '1000']"
        show-less-items
        show-quick-jumper
        show-size-changer
        :show-total="(total) => `总 ${total} 条`"
        @change="pagerChange"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, useSlots, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { setItem, removeItem, getItem } from '@/utils/storage'
import { PlusOutlined } from '@ant-design/icons-vue'

// 插槽处理
const slots = useSlots()
const emit = defineEmits(['onAdd', 'queryEnd'])
const renderArr = Object.keys(slots)
const router = useRouter()

// props
const props = defineProps({
  // 列配置项
  columns: {
    type: Array,
    default: () => [],
  },
  // 筛选条件表单数据
  queryForm: {
    type: Object,
    default: {},
  },
  // 查询Api
  queryApi: Function,
  /**
   * 处理数据方法
   * 父组件如有处理数据需求，传此方法，需返回结果
   */
  handleData: Function,
  // 是否组件挂载就请求数据
  isMounted: {
    type: Boolean,
    default: true,
  },
  // 是否需要分页
  pagination: {
    type: Boolean,
    default: true,
  },
  // 表格左侧标题
  title: {
    type: String,
    default: '',
  },
  // 是否展示新增按钮
  hasAdd: {
    type: Boolean,
    default: true,
  },
  // 是否展示返回按钮
  hasBack: {
    type: Boolean,
    default: false,
  },
  summary: {
    type: String,
    default: '',
  },
})

// 分页数据
const pager = reactive({
  data: {
    pageSize: 10,
    current: 1,
    total: 0,
  },
})
// 页码或者尺寸改变
const pagerChange = (page, pageSize) => {
  pager.data.pageSize = pageSize
  pager.data.current = page
  queryData()
}

// 表格数据
const dataSource = ref([])
const tableLoading = ref(false)
const summaryTxt = ref('')

const queryData = () => {
  if (props.queryApi) {
    tableLoading.value = true
    const params = {}
    if (props.pagination) {
      params.current = pager.data.current
      params.pageSize = pager.data.pageSize
      // 缓存分页
      setItem('tempPager', {
        current: pager.data.current,
        pageSize: pager.data.pageSize,
      })
    }
    const queryFormData = toRaw(props.queryForm)
    props
      .queryApi({
        ...params,
        ...queryFormData,
      })
      .then((res) => {
        if (res.status === 0) {
          const list = res.data || []
          dataSource.value = props.handleData ? props.handleData(list) : list

          if (props.pagination) {
            pager.data = res.pagination
          }

          if (props.summary) {
            summaryTxt.value = list[0] ? list[0][props.summary] : ''
          }
          tableLoading.value = false
          emit('queryEnd', list)
        } else {
          tableLoading.value = false
        }
      })
      .catch((err) => {
        console.log('err:', err)
        tableLoading.value = false
      })
  }
}

// 表格重载（分页清除）
const reload = (params) => {
  // if (params && params.current && params.pageSize) {
  //   pager.data = {
  //     pageSize: params.pageSize,
  //     current: params.current,
  //     total: 0,
  //   }
  // } else {
  //   pager.data = {
  //     pageSize: 10,
  //     current: 1,
  //     total: 0,
  //   }
  // }
  pager.data = {
    pageSize: 10,
    current: 1,
    total: 0,
  }
  removeItem('tempPager')
  queryData()
}

// 加载数据（保留分页）
const loadData = () => {
  let tempPager = getItem('tempPager')
  if (tempPager && tempPager.current) {
    pager.data = {
      pageSize: tempPager.pageSize,
      current: tempPager.current,
      total: 0,
    }
  }
  queryData()
}

// 新增点击
const addClick = () => {
  emit('onAdd')
}

// 返回点击
const backClick = () => {
  setItem('isBack', true)
  router.back()
}

// 组件挂载
onMounted(() => {
  if (props.isMounted) {
    queryData()
  }
})

defineExpose({
  reload,
  loadData,
  dataSource,
  pager,
})
</script>

<style lang="scss" scoped>
.add_line {
  margin-bottom: 10px;
}
.pager_wrap {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .summary {
    margin-right: 15px;
    font-weight: bold;
  }
}
</style>
